import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import ux from "../../images/article3.jpg";
import nadeeka from "../../images/Nadeeka.png";
import read from "../../images/blog-ux.png";
import exp from "../../images/blog-wireframes.jpeg";
import interact from "../../images/blog-meetup.jpeg";
import IntroBanner from "../../components/IntroBanner";

const PostUx = (props) => {
  return (
    <Layout bodyClass="page-blog">
      <Seo
        title="Getting started as a UX Intern"
        description=" Whether you are an undergraduate searching for an internship
                    for the first time, or someone who is already working in
                    another career path, you may have heard about the field of
                    User Experience."
        image={ux}
      />
      <IntroBanner
        title="Getting started as a UX intern"
        by="By Nadeeka Athukorala"
        image={ux}
      />
      <div className="content">
        <div className="container pt-4 pt-md-4">
          <div className="row justify-content-start">
            <div className="col-12 col-md-12">
              <div className="content-body">
                <div className="content">
                  <p>
                    Whether you are an undergraduate searching for an internship
                    for the first time, or someone who is already working in
                    another career path, you may have heard about the field of
                    User Experience. Maybe you have followed up and read some
                    articles about it, been to a few meetups, and now you have
                    developed an interest in the field. Maybe you think you have
                    what it takes to dive in, but before you can dive, you need
                    to get into the water first.
                  </p>
                  <p>
                    <b>
                      <i>
                        “It is easier to resist at the beginning than at the
                        end.”
                      </i>
                    </b>
                    <br />
                    <i>― Leonardo da Vinci</i>
                  </p>
                  <p>
                    {" "}
                    No matter how interested you are, you will find yourself
                    procrastinating on that decision to get started. There are a
                    lot of articles about getting started as a UX intern,
                    landing an intern position and so on. If you are someone who
                    is planning to get started as a UX intern and are reading
                    this article at this very moment, congratulations, for
                    resisting the resistance and following your dream! When I
                    first wanted to get started as a UX intern, I too read a lot
                    of posts about getting started.
                    <span className="highlight-link">
                      <Link
                        to="https://uxdesign.cc/3-reasons-new-ux-designers-cant-find-jobs-bb3b964f37bc"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {" "}
                        3 reasons new UX Designers can’t find jobs
                      </Link>
                    </span>{" "}
                    is one of my favorite posts about being a newbie UX
                    designer.
                  </p>
                  <p>
                    If you are returning after reading the above post, you might
                    feel a little demotivated, but don’t worry, I promise to fix
                    it by the end of this article.
                  </p>
                  <p>
                    Let’s get back to the subject. I’ll be explaining the
                    process in 3 steps.
                  </p>
                  <div className="d-lg-flex d-block">
                    <div className="wrap-img">
                      <div>
                        <b>1. Read</b>
                      </div>
                      <img className="img-fluid" alt="read" src={read} />
                      <div className="utext mb-4">
                        via https://peoplevendor.com/ux-design-services/
                      </div>
                    </div>
                    <div>
                      <p>
                        Whether it is User Experience or gardening, if you are
                        interested in something, you better not be ignorant.
                      </p>
                      <p>
                        <b>
                          “In the age of information, ignorance is a choice.”
                        </b>{" "}
                        <br />
                        <i>― Donny Miller</i>
                      </p>
                      <p>
                        With the internet, you have access to almost any kind of
                        information, so all you need to know is at your
                        fingertips. So what exactly should you read-up on? You
                        can simply start by searching{" "}
                        <b>“What is User Experience?”</b>, then you will realize
                        that there are a lot of people who are already in the
                        game and helping beginners.
                      </p>
                      <p>
                        Starting from there, you can search and find out about
                        related job roles, pay scale and all the{" "}
                        <b>background information,</b> because you must “Look
                        before you leap”, and in order to look, you need to know
                        where to look and have all the facts. Depending on the
                        country you live in, you might not find all the
                        information relating to the job market on the internet.
                        In that case you will have to ask someone who’s already
                        in the game. I will return to that point later.
                      </p>
                      <p>
                        So once you know the background information relating to
                        building up a career in UX, you can decide whether to
                        jump in or not. If you still choose to jump in, then
                        it’s time to decide which role you want to take in UX,
                        because there are different hats that you can wear; for
                        example: User researcher, UX designer, UX engineer, etc.
                        However, in Sri Lanka we rarely find people wearing one
                        hat exclusively. Most of them wear multiple hats;
                        meaning they have multiple skills and multiple
                        responsibilities. So it’s better to be prepared for a
                        wider scope. It will also help you stay ahead of the
                        game.
                      </p>
                    </div>
                  </div>
                  <p>
                    <b>Read about the skills</b> you need to acquire in order to
                    become a UX engineer; all of them might not be needed to
                    become an intern but if you are planning a long-term career,
                    you better list them down and analyze what you already have
                    and what you don’t.
                  </p>
                  <p>
                    Moving forward you can then tick off your checklist while
                    acquiring those skills. I literally had this done and pasted
                    on the wall in front of my workspace so it was a working
                    reminder for me and it was also fulfilling to see the to-do
                    list getting smaller.
                  </p>
                  <p>
                    Now you know what are the skills that it takes and you have
                    a to-do list too. There should be something which drives all
                    those skills; the{" "}
                    <b>theoretical knowledge and the concepts of UX</b>. To gain
                    the theoretical knowledge you can follow an online course,
                    subscribe to a UX newsletter, follow some UX blogs, read UX
                    related books or you can even register for a degree/any kind
                    of course relating to UX depending on your need and how much
                    time you have on your hands. In my case, I had a separate
                    module for UX within my degree program where the lectures
                    were conducted by an experienced UX engineer, giving us
                    beginners a good start.
                  </p>
                  <div>
                    <div>References :</div>
                    <ol>
                      <li className="highlight-link">
                        <Link
                          to="https://medium.com/@alexewerlof/what-is-a-ux-engineer-1286d4b6d0e8"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Who is a “UX Engineer”?
                        </Link>
                      </li>
                      <li className="highlight-link">
                        <Link
                          to="https://www.uxbeginner.com/how-to-navigate-the-ocean-of-ux-job-titles/"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          How to Navigate the Ocean of UX Job Titles
                        </Link>
                      </li>
                      <li className="highlight-link">
                        <Link
                          to="https://www.uxbeginner.com/ux-reading-list/"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          UX beginner’s reading list
                        </Link>
                      </li>
                      <li className="highlight-link">
                        <Link
                          to="https://uxdesign.cc/"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          UX Collective
                        </Link>
                      </li>
                    </ol>
                  </div>

                  <div className="d-lg-flex d-block mt-5">
                    <div className="wrap-img">
                      <div>
                        <b>2. Hands-on Experience</b>
                      </div>
                      <img className="img-fluid" alt="read" src={exp} />
                      <div className="utext mb-4">
                        via https://octa-digital.co/blog/
                      </div>
                    </div>
                    <div>
                      <p>
                        A well-structured internship will be the best way to
                        gain hands-on experience, but before that you can start
                        on your own too.
                      </p>
                      <p>
                        Reading content that someone else has written will not
                        be sufficient to get what you really want. Also, written
                        content may not always contain everything. There are
                        things that you can only learn by experience. Hence
                        hands-on experience is important. Once you read about
                        the skills and theories, you can start applying them.
                      </p>
                      <p>Few ways that you can do that.</p>
                      <ol>
                        <li>Create dummy projects for your own new ideas.</li>
                        <li>
                          Subscribe to something like{" "}
                          <span className="highlight-link">
                            <Link
                              to="https://www.dailyui.co/"
                              rel="noreferrer noopener"
                              target="_blank"
                            >
                              https://www.dailyui.co/
                            </Link>
                          </span>{" "}
                          and get a daily challenge for yourself
                        </li>
                        <li>
                          Copy existing products to practice your design tools
                        </li>
                        <li>Do volunteer work relating to UX</li>
                        <li>If you are confident enough, start freelancing</li>
                      </ol>
                      <p>
                        This way you will have tangible proof to show your
                        skills at an interview.
                      </p>{" "}
                    </div>
                  </div>

                  <div className="d-lg-flex d-block mt-5">
                    <div className="wrap-img">
                      <div>
                        <b>3. Interact</b>
                      </div>
                      <img
                        className="img-fluid"
                        alt="interact"
                        src={interact}
                      />
                      <div className="utext mb-4">
                        via https://dribbble.com/stories/categories/meetups
                      </div>
                    </div>
                    <div>
                      <p>
                        Doing a lot of work and keeping them in your local
                        machine without getting any feedback from anyone will
                        not do any good for you because it’s “User Experience”;
                        only your own understanding of your work will not be
                        enough because you are building it for others too. Share
                        your work publicly in a place where experienced people
                        in UX/design are able to access it. You can share your
                        work on Facebook, Twitter, Instagram and even on
                        LinkedIn in general.
                      </p>
                      <p>
                        If you are planning to have a collection of your work as
                        a portfolio and get discovered by other professionals in
                        UX/designing to get their feedback, it’s a better idea
                        to have an online portfolio on platforms like{" "}
                        <span className="highlight-link">
                          <Link
                            to="https://dribbble.com/"
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            Dribbble
                          </Link>
                        </span>{" "}
                        or{" "}
                        <span className="highlight-link">
                          <Link
                            to="https://www.behance.net/"
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            Behance
                          </Link>
                        </span>{" "}
                        — or you can even have your own portfolio website.
                      </p>
                      <p>
                        You will find it very inspiring when you receive
                        comments on your work. Be open-minded to all criticism;
                        don’t take it personally and always focus on improving
                        your work. You might also get opportunities to work
                        depending on the quality of your portfolio as people
                        discover you on the internet.
                      </p>
                    </div>
                  </div>
                  <p>
                    Another way that you can interact online is to join design
                    communities. Follow their work on social media and you may
                    be able to reach out to them when you get problems while
                    practicing your skills. When you interact online, you will
                    also get to know about events and meetups related to UX. The
                    reason why you should attend those meetups is that you can
                    meet experienced people and start networking. They might
                    help you get started and find answers to the questions you
                    were not able to answer just by reading and practicing.
                    Always learn from the experiences of others, because your
                    life is not enough to experience everything.{" "}
                    <i>So meetups are not just for swag!</i>
                  </p>
                  <hr />
                  <p>Some of the questions you might be having right now:</p>
                  <p>
                    <b>Do I have to follow these steps in order?</b>
                  </p>
                  <p>
                    No. You can even start with going to a meetup, meeting
                    people, then start doing some work and when you get issues
                    in your work you can refer to books or the internet and find
                    answers. You can do it in your own way, be creative! This is
                    just one template.
                  </p>
                  <p>
                    <b>
                      Do I have to complete each step before I prepare my CV and
                      apply for an internship?
                    </b>
                  </p>
                  <p>
                    No, you can even start preparing your CV without these, but
                    make sure to highlight your creative abilities, if you are
                    familiar with any front-end technologies, and any interest
                    that matches with the place you are trying to secure. Once
                    you are called for an interview do not resist to walk in
                    just because you haven’t completed the above steps. The
                    worst thing that could happen is that you might fail an
                    interview, but trust me you will be more thankful for the
                    interviews which you failed than the ones you passed because
                    they are definitely a reminder for you to work on yourself a
                    little bit more.
                  </p>
                  <p>So while working on the above steps, take interviews.</p>
                  <p>
                    <i>
                      <b>
                        “Ever tried. Ever failed. No matter. Try again. Fail
                        again. Fail Better”
                      </b>
                    </i>
                    <br />― Richard Branson
                  </p>
                  <p>
                    If you are waiting until you have completed all of the
                    above, you might end up being too late and wasting your
                    time. If you have at least started on these steps it will
                    prevent you from being a baby in the UX field.
                  </p>
                  <p>As my first reference explains:</p>
                  <p>
                    <b>
                      <i>
                        …Babies are kind of useless.
                        <br />
                        Totally. Completely. Useless.
                        <br />
                        Regardless of if you are fond of babies or not, we can
                        all agree that for the first part of their lives they
                        don’t know anything. Sure- being created from scratch
                        endows you with a lot of privileges- but you’ll need a
                        lot more than just “I’m a human” to be truly successful
                        at your life
                      </i>
                    </b>
                  </p>
                  <p>
                    So if you go walking into an interview with only enthusiasm
                    but with zero knowledge you will be a baby. You might be in
                    UX but you are not useful yet, you still lack those
                    qualities which are needed to be a UX engineer. So, try your
                    best to escape that phase and be useful.
                  </p>
                  <p>
                    <i>
                      <b>
                        “If you’re not embarrassed by the first version of your
                        product, you’ve launched too late. ”
                      </b>
                      <br />— Reid Hoffman
                    </i>
                  </p>
                  <p>
                    So launch the product of yourself as early as possible.
                    Also,
                  </p>
                  <p>
                    <i>
                      <b>
                        “This is not to say that extremely skilled UX/UI
                        designers are no longer important, it’s more to say that
                        the juniors of today now have access to tools and
                        patterns which bring them extremely close to the skill
                        set of the seniors of the last decade. The problem with
                        “good enough” is that if you’re looking for a long,
                        fruitful career, then good-enough is not enough. ”
                      </b>
                    </i>
                    <br />
                    <i>– AJ&Smart on Youtube</i>
                  </p>
                  <p>
                    So if you can easily be good enough, be it. Once you reach
                    there, expand your horizons and work on staying ahead of the
                    game! Good Luck!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="author mt-5">
        <div className="container">
          <div className="row wrap-auther-detail">
            <div className="author-image">
              <img alt="Nadeeka Athukorala" src={nadeeka} />
            </div>
            <div className="d-flex align-items-center">
              <div className="ml-lg-3 ml-sm-0 detail">
                <h4>
                  <i>Nadeeka Athukorala</i>
                </h4>
                <span>
                  <i>iTelaSoft UI/UX Team</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "article1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default PostUx;
